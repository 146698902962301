<template>
  <div>
    <app-page-edit page-title="View Announcement" page-toolbar-title="View Announcement">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()"></action-button>
      </template>

      <v-form :disabled="loading_save">
        <v-row>
          <v-col cols="12">
            <!-- General -->
            <card-expansion title="General" class="mb-3" :loading="loading">
              <v-row >
                <v-col class="py-2" cols="12" md="12">
                  <v-text-field readonly disabled dense v-model="data_detail.title" placeholder="Title" label="Title" outlined hide-details class="mb-2"></v-text-field>
                  <!-- <VueEditor v-model="data_detail.content" class="mb-2"></VueEditor> -->
                </v-col>
              </v-row>

              <v-sheet outlined rounded class="pa-4">
                <div v-html="data_detail.content"></div>
              </v-sheet>

            </card-expansion>
          </v-col>
        </v-row>
      </v-form>
    </app-page-edit>
  </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import ActionButton from './components/ActionButton.vue';


export default {
  components: {
    // VueEditor,
    ActionButton,
  },
  data() {
    return {
      id: this.$route.query.id,
      loading: false,
      loading_delete: false,
      modal1: false,
      data_detail: {},

      saveControl: false,
    };
  },
  watch: {
  },
  methods: {
    refreshDetail() {
      this.getData(true);
    },
    async getData(refresh = false) {

      this.loading = true;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "eprocannouncement/detail");

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("eprocannouncement/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.showLoadingOverlay(false);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.data_detail.id);
        await this.$axios
          .post("eprocannouncement/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);
            this.refreshData();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Delete?";
      this.showConfirm("Confirm", msg, post);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
