<template>
  <div class="d-flex">
    <app-button @click="refreshDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
    <app-button v-if="!isDetailPage" @click="redirect('Purchasing.EprocAnnouncement.Detail', { id: data_detail.id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
    <app-button v-if="!isEditPage" @click="redirect('Purchasing.EprocAnnouncement.Edit', { id: data_detail.id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <app-button v-if="isEditPage" :loading="loading_save" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
    <app-button :loading="loading_delete" @click="deleteData()" mdi-icon="mdi-delete" title="Delete"></app-button>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
    loadingSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      // loading_receive: false,
      loading_cancel: false,
      loading_attachment: false,
      loading_reject: false,
      loading_unsubmit: false,
      loading_review: false,
      loading_save: this.loadingSave,
      data_detail: {},
    }
  },
  watch: {
    dataDetail() {
      this.data_detail = this.dataDetail;
    },
    loadingSave() {
      this.loading_save = this.loadingSave;
    },
    loading_save() {
      this.loading = this.loading_save;
    }
  },
  computed: {
    isDetailPage() {
      if (this.$route.name == 'Purchasing.EprocAnnouncement.Detail') {
        return true;
      }
      return false;
    },
    isEditPage() {
      if (this.$route.name == 'Purchasing.EprocAnnouncement.Edit') {
        return true;
      }
      return false;
    },
  },
  methods: {

    refreshDetail() {
      this.$emit('refresh-detail')
    },

    saveData() {
      this.$emit('save-click')
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel/Open
     */
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.data_detail.id);
        await this.$axios
          .post("eprocannouncement/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);
            this.refreshData();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      let msg = "Delete?";
      this.showConfirm("Confirm", msg, post);
    },
  }
}
</script>